const Matomo = class {

  /*
  |--------------------------------------------------------------------------
  | Interface
  |--------------------------------------------------------------------------
  */

  /**
   */
  init (url, id) {

    /* global _paq */
    window._paq = []
    _paq.push(['requireConsent'])
    //_paq.push(['trackPageView'])
    _paq.push(['enableLinkTracking'])
    _paq.push(['setTrackerUrl', fn.toUrl(url, 'matomo.php')])
    _paq.push(['setSiteId', id])

    var el = document.createElement('script')
    var scripts = document.getElementsByTagName('script')[0]
    el.type = 'text/javascript'
    el.async = true
    el.defer = true
    el.src = fn.toUrl(url, 'matomo.js')
    scripts.parentNode.insertBefore(el, scripts)
  }

  /**
   */
  track (path, referrer) {
    _paq.push(['setConsentGiven'])
    _paq.push(['setCustomUrl', path])
    if (referrer) {
      _paq.push(['setReferrerUrl', referrer])
    }
    _paq.push(['trackPageView'])
  }
}

export default Matomo